.screensaver-container {
  height: 100%;
  width: 100%;
  float: left;
  top: 0;
  padding: none;
  position: fixed;
  z-index: 999999999;
}

.screensaver {
  height: 100%;
  padding: none;
  position: fixed;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  cursor: pointer;
}

.react-datepicker__day--outside-month {
  visibility: hidden;
}

.react-datepicker__year-select {
  width: 100px;
  text-align: center;
}
