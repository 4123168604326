@font-face {
  font-family: StrawFord;
  src:
    url("./fonts/Strawford-Regular.otf") format("opentype"),
    url("./fonts/Strawford_Regular.ttf") format("truetype"),
    url("./fonts/strawford-regular-webfont.eot") format("embedded-opentype");
}

body {
  font-family: "StrawFord", sans-serif !important;
  min-height: 100vh;
}

.card {
  border: 0px !important;
}

.dispensed-logo {
  max-height: 50px;
}

.svg-icon {
  height: 32px;
  width: 32px;
}

.svg-logo-icon {
  width: 120px;
  height: 16px;
  z-index: 3;
}

.sign-up-steps {
  width: 100%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  font-size: 16px;
  line-height: 1.5;
  margin-bottom: 32px;
  counter-reset: section;
  position: relative;
  list-style: none;
}

.sign-up-steps__item {
  position: relative;
  padding-left: 32px;
}

.sign-up-steps__item:before {
  background: #eff3f9;
  counter-increment: section;
  content: counter(section);
  width: 24px;
  height: 24px;
  position: absolute;
  left: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  color: #060a20;
  font-size: 14px;
  border-radius: 50%;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.sign-up-steps__item.active-step:before {
  background: #060a20;
  color: #fff;
}

.sign-up-steps__item.success-step:before {
  background: #4fcfb0;
  content: url("./assets/sign-up-check-icon.svg");
}

.sign-up-steps__item:not(:last-of-type) {
  margin-right: 24px;
  padding-right: 24px;
}

.sign-up-steps__item:not(:last-of-type):after {
  content: url("./assets/sign-up-arrow.svg");
  position: absolute;
  right: 0;
  top: 50%;
  -webkit-transform: translateY(-50%);
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
}

.register-header:before {
  background: rgba(0, 0, 0, 0.15);
  content: "";
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
}

.register-header {
  background-image: url("./assets/popup-bg.jpg");
  background-position: unset;

  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  -ms-flex-direction: column;
  flex-direction: column;
  -webkit-box-align: start;
  -ms-flex-align: start;
  align-items: flex-start;
  background-size: cover;
  border-top-left-radius: 24px;
  border-bottom-left-radius: 24px;
  border-bottom: none;
  position: relative;
  overflow: hidden;
  height: 100%;

  justify-content: space-between;

  padding: 4rem;
}

.register-welcome-text {
  line-height: 1.5;
  z-index: 3;
  font-size: 20px;
}

.bg-custom-primary {
  background-color: rgb(6, 10, 32) !important;
}

.register-card {
  border-radius: 24px !important;
}

.content-centered {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  min-height: 100vh;
}

.form-label {
  font-size: 14px;
  line-height: 1.643;
  color: #6f7887;
  font-weight: 500;
  margin-bottom: 8px;
  letter-spacing: 0.7px;
}

.form_control {
  background-color: #eff3f9;
  border: none;
  border-radius: 16px;
  color: #060a20;
  letter-spacing: 0.7px;
  border: 2px solid #eff3f9;
  padding: 14px 16px;
}

.form_control:focus {
  background: #fff;
}

.form_control::-webkit-input-placeholder {
  color: #b4bac2;
}

.form_control::-moz-placeholder {
  color: #b4bac2;
}

.form_control:-ms-input-placeholder {
  color: #b4bac2;
}

.form_control::-ms-input-placeholder {
  color: #b4bac2;
}

.form_control::placeholder {
  color: #b4bac2;
}

.main_btn {
  background: rgb(6, 10, 32) !important;
  width: 100%;
  display: block;
  color: #fff !important;
  letter-spacing: 0.7px !important;
  border-radius: 16px !important;
  padding: 15px 16px !important;
}
