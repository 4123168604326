.overlay {
  position: absolute;
  top: 0;
  background-color: rgba(0, 0, 0, 0.8);
  width: 100%;
  height: 100%;
  z-index: 1000;
}

.reset-button {
  position: absolute;
  bottom: 5%;
  padding: 8px;
  background-color: rgb(7, 33, 84);
  color: white;
  border: none;
  border-radius: 5px;
  z-index: 999999444499 !important;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
  right: 5%;
  transform: translateX(-50%);
  width: auto;
  font-size: 1.1rem;
}
