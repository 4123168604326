@import url("https://font.typeform.com/dist/google/poppins/index.css");

body {
  font-family: "Poppins";
}

.typeform-btn {
  position: relative;
  font-family: inherit;
  line-height: inherit;
  font-weight: 700;
  cursor: pointer;
  transition-duration: 0.1s;
  transition-property: background-color, color, border-color, opacity,
    box-shadow;
  transition-timing-function: ease-out;
  outline: none;
  border: 1px solid transparent;
  margin: 0px;
  box-shadow: rgb(0 0 0 / 10%) 0px 3px 12px 0px;
  padding: 6px 14px;
  min-height: 40px;
  background-color: rgb(7, 33, 84);
  color: rgb(247, 251, 255);
  border-radius: 4px;
}

.typeform-btn:hover {
  background-color: rgb(44, 66, 110);
}

.typeform-btn-text {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  font-size: 20px;
  line-height: 28px;
}

.typeform-text-input {
  display: block;
  width: 100%;
  font-family: inherit;
  color: rgb(1, 66, 172);
  padding: 0px 0px 8px;
  border: none;
  outline: none;
  border-radius: 0px;
  appearance: none;
  background-image: none;
  background-position: initial;
  background-size: initial;
  background-repeat: initial;
  background-attachment: initial;
  background-origin: initial;
  background-clip: initial;
  transform: translateZ(0px);
  font-size: 26px;
  -webkit-font-smoothing: antialiased;
  line-height: unset;
  -webkit-text-fill-color: rgba(1, 66, 172);
  animation: 1ms ease 0s 1 normal none running native-autofill-in;
  transition:
    background-color 1e8s ease 0s,
    box-shadow 0.1s ease-out 0s;
  box-shadow: rgb(1 66 172 / 30%) 0px 1px;
  background-color: transparent !important;
}

.typeform-text-input.is-invalid {
  box-shadow: #dc3545 0px 1px;
}

::placeholder {
  /* Chrome, Firefox, Opera, Safari 10.1+ */
  -webkit-text-fill-color: rgba(1, 66, 172, 0.3);
}

:-ms-input-placeholder {
  /* Internet Explorer 10-11 */
  -webkit-text-fill-color: rgba(1, 66, 172, 0.3);
}

::-ms-input-placeholder {
  /* Microsoft Edge */
  -webkit-text-fill-color: rgba(1, 66, 172, 0.3);
}

.typeform-text-input:focus {
  box-shadow: rgb(1 66 172) 0px 2px;
}

.typeform-label-wrapper {
  margin: 0px;
  max-width: 100%;
  width: inherit;
  font-weight: unset;
  font-size: 24px;
  line-height: 32px;
  color: rgb(5, 18, 158);
}
